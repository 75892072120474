import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Getting Started",
  "sortOrder": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Getting Started`}</h1>
    <p className="p-large">Beginning to use Caesars UI is easy.</p>
    <p>{`Learn how you can start using it in seconds.`}</p>
    <p><a parentName="p" {...{
        "href": "/getting-started/getting-started-for-designers"
      }}>{`For designers`}</a></p>
    <p><a parentName="p" {...{
        "href": "/getting-started/getting-started-for-developers"
      }}>{`For developers`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      